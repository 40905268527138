/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Link,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import EventForm from 'components/EventForm'
import SalesResume from 'components/SalesResume'
import SectorBatches from 'components/SectorBatches'
import { constants } from 'config'
import { DiscountCoupons } from 'pages/promoter/DiscountCoupons'
import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useNavigate, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'
import EventStaff from 'widgets/EventStaff'
import { UploadOrdersCSV } from './UploadOrdersCSV'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  }
}

export default function EventDetails() {
  const { eventId } = useParams()
  const theme = useTheme()
  const [, setError] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [event, setEvent] = React.useState<IEvento>()
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
  }

  React.useEffect(() => {
    functionWithLoading(
      apiV1.admin.eventService
        .get(Number(eventId))
        .then((res) => {
          setEvent(res.data)
        })
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoading,
    )
  }, [eventId])

  if (loading || !event)
    return (
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    )

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
      }}
    >
      <AppBar position="static" color="default">
        <img
          src={constants.URL_FTP + event.imagem}
          style={{ aspectRatio: '16 / 9', maxHeight: 120 }}
          alt=""
        />
        <Typography variant="h6" textAlign="center">
          {event.titulo}
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs"
        >
          <Tab label="Resumo de vendas" {...a11yProps(0)} />
          <Tab label="Dados" {...a11yProps(1)} />
          <Tab label="Lotes" {...a11yProps(2)} />
          <Tab label="Equipe" {...a11yProps(3)} />
          <Tab label="Cupons" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <Button
        variant="outlined"
        onClick={() => navigate(`/admin/events/${eventId}/export-customers`)}
        sx={{ my: 2, mr: 2 }}
      >
        Exportar clientes
      </Button>

      <Button
        variant="outlined"
        onClick={() =>
          navigate(`/organizador/eventos/${eventId}/solicitacao-de-saque`)
        }
      >
        Solicitar saque
      </Button>

      <UploadOrdersCSV eventId={eventId ?? 0} />

      {event.promoter && (
        <Alert severity="info" sx={{ mt: 1 }}>
          [{event.promoter.razao_social} - {event.promoter.telefone}] - [
          {[event.promoter.usuario.nome, event.promoter.usuario.sobrenome].join(
            ' ',
          )}{' '}
          -{' '}
          <Link
            href={`https://wa.me/55${[
              event.promoter.usuario.ddd,
              event.promoter.usuario.telefone,
            ].join('')}`}
            target="_blank"
          >
            {[event.promoter.usuario.ddd, event.promoter.usuario.telefone].join(
              '',
            )}
          </Link>
          ]
        </Alert>
      )}

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SalesResume eventId={Number(eventId)} isAdmin />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <EventForm eventId={Number(eventId)} isAdmin />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SectorBatches eventId={Number(eventId)} isAdmin />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <EventStaff eventId={Number(eventId)} isAdmin />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <DiscountCoupons isAdmin />
        </TabPanel>
      </SwipeableViews>
    </Box>
  )
}
