import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { constants } from 'config'
import { LogoutUser } from 'contexts/userContext'

const clientV2 = axios.create({
  baseURL: constants.URL_API + 'api/v2/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  },
})

clientV2.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response.data.message === 'token.invalid'
    ) {
      LogoutUser()
      const requestConfig = error.config
      return axios(requestConfig)
    }
    return Promise.reject(error)
  },
)

clientV2.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export default clientV2
