import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material'
import CountdownTimer from 'components/CountdownTimer'
import { ShoppingCartItem } from 'components/ShoppingCartItem'
import { useShoppingCart } from 'contexts/shoppingCartContext'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useCallback } from 'react'
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom'
import { IEvento } from 'types/evento'
import { moneyFormatter } from 'utils/formatter'
import { localDate } from 'utils/functions'

interface ShoppingCartProps {
  event: IEvento
  refreshEvent: () => void
}

const ShoppingCart = ({ event, refreshEvent }: ShoppingCartProps) => {
  const navigate = useNavigate()
  const [useTerm, setUseTerm] = React.useState(true)
  const { shoppingCart, totalShoppingCartValue } = useShoppingCart()

  const handleBuy = useCallback(() => {
    if (event.id === 729) {
      return navigate(`/checkout`, {
        state: { eventId: event.id, shoppingCart },
      })
    }
    return navigate(`/evento/${event.id}/checkout`, {
      state: {
        googleAnalytics: event.google_analytics,
        googleTagManager: event.google_tag_manager,
        metaPixel: event.facebook_pixel,
        shoppingCart,
      },
    })
  }, [
    event.facebook_pixel,
    event.google_analytics,
    event.google_tag_manager,
    event.id,
    navigate,
    shoppingCart,
  ])

  const renderContent = useCallback(() => {
    if (new Date(event.data_entrar) >= localDate()) {
      return (
        <>
          <Typography variant="h6" textAlign="center">
            As vendas começam em
          </Typography>
          <CountdownTimer
            targetDate={moment(event.data_entrar)
              .add(3, 'hours')
              .toDate()
              .getTime()}
            callback={refreshEvent}
          />
        </>
      )
    }

    if (event.venda_suspensa) {
      return (
        <Typography variant="h6" textAlign="center">
          As vendas estão suspensas!
        </Typography>
      )
    }

    if (new Date(event.data_encerrar_vendas) <= localDate()) {
      return (
        <Typography variant="h6" textAlign="center">
          Vendas on-line encerradas!
        </Typography>
      )
    }

    const hasAvailableBatches =
      event.setor.reduce(
        (quantityTickets, sector) => quantityTickets + sector.ingresso.length,
        0,
      ) > 0

    if (!hasAvailableBatches) {
      return (
        <Typography variant="h6" textAlign="center">
          Nenhum ingresso disponível
        </Typography>
      )
    }

    const hoursForTheEventToStart = dayjs(event.data).diff(dayjs(), 'hours')

    return (
      <React.Fragment>
        {event.setor.map((sector) => (
          <List
            key={sector.id}
            subheader={
              <ListSubheader>
                <Typography variant="overline">{sector.titulo}</Typography>
              </ListSubheader>
            }
            sx={{
              border: 'solid #d0d0d0 1px',
              borderRadius: 2,
              marginX: 1,
            }}
          >
            {sector.ingresso.map((ticket) => (
              <ShoppingCartItem key={ticket.id} batch={ticket} />
            ))}
          </List>
        ))}
        {hasAvailableBatches && (
          <React.Fragment>
            {shoppingCart.length > 0 && hoursForTheEventToStart < 48 && (
              <ListItem>
                <Alert severity="warning" title="Atenção">
                  Essa compra não poderá ser cancelada pois restam menos de 48
                  horas para começar o evento. De acordo com nossos{' '}
                  <Link
                    component={RouterLink}
                    to={'/termos-de-uso'}
                    target="_blank"
                  >
                    termos de uso
                  </Link>
                  .
                </Alert>
              </ListItem>
            )}
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={useTerm} />}
                  label={
                    <Typography variant="caption">
                      Concordo com os{' '}
                      <Link
                        component={RouterLink}
                        to={'/termos-de-uso'}
                        target="_blank"
                      >
                        termos de uso
                      </Link>
                      .
                    </Typography>
                  }
                  sx={{ alignItems: 'center' }}
                  onChange={() => setUseTerm((s) => !s)}
                />
              </FormGroup>
            </ListItem>
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h6">
                  Total {moneyFormatter.format(totalShoppingCartValue)}
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleBuy}
                  disabled={!useTerm || !shoppingCart.length}
                >
                  Comprar
                </Button>
              </Box>
            </ListItem>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }, [
    event.data,
    event.data_encerrar_vendas,
    event.data_entrar,
    event.setor,
    event.venda_suspensa,
    handleBuy,
    refreshEvent,
    shoppingCart.length,
    totalShoppingCartValue,
    useTerm,
  ])

  if (!event.visivel) {
    return <Navigate to="/" />
  }

  return (
    <Box sx={{ top: '1rem' }}>
      <Box
        component={Paper}
        sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1, gap: 1 }}
      >
        {renderContent()}
      </Box>
      <Alert
        security="primary"
        severity="info"
        sx={{ mt: 2, whiteSpace: 'pre-wrap' }}
      >
        {event.retirada}
      </Alert>
    </Box>
  )
}

export default ShoppingCart
