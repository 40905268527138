import { Container, Grid, Paper, Skeleton, TableHead } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Loading } from 'components'
import { Button } from 'components/button'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import clientAdmin from 'services/api/v1/admin/client'
import { IUsuario } from 'types/usuario'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'

export function UserDetails() {
  const [user, setUser] = useState<IUsuario>()
  const [isLoading, setIsLoading] = useState(false)

  const { addErrorMessage } = useAdmin()
  const { userId } = useParams()

  useEffect(() => {
    async function main() {
      try {
        setIsLoading(true)
        const { data } = await clientAdmin.get(`/users/${userId}`)
        setUser(data.user)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }

    main()
  }, [addErrorMessage, userId])

  async function handleToggleBlock() {
    try {
      setIsLoading(true)
      await clientAdmin.patch(`/users/${userId}/toggle-block`)
      setUser((prev) => (prev ? { ...prev, bloqueado: !prev.bloqueado } : prev))
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading)
    return (
      <Loading>
        <Skeleton variant="rectangular" height={400} />
      </Loading>
    )

  if (!user) return null

  return (
    <Container maxWidth="xl" component={Paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Detalhes do usuário</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>Nome:</TableCell>
                  <TableCell>
                    {[user.nome, user.sobrenome].join(' ').trim().toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>CPF:</TableCell>
                  <TableCell>{user.cpf}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>E-mail:</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>Telefone:</TableCell>
                  <TableCell>
                    {user.ddd}
                    {user.telefone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>Cidade:</TableCell>
                  <TableCell>
                    {user.brasil_cidade?.cidade} - {user.brasil_cidade?.uf}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>Bloqueado:</TableCell>
                  <TableCell>
                    <Button size="small" onClick={handleToggleBlock}>
                      {user.bloqueado ? 'Desbloquear' : 'Bloquear'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Notas:</Typography>
          {user.usuario_nota.map((nota) => (
            <Typography key={nota.id}>
              {dayjs(nota.datacadastro).format('DD/MM/YY HH:mm')} - {nota.tipo}{' '}
              - {nota.texto}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Evento</TableCell>
                  <TableCell>Situação</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.pedido.map((order) => (
                  <TableRow
                    key={order.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/admin/order-details/${order.codigo}`}
                        target="_blank"
                      >
                        {order.codigo}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {dayjs(order.data)
                        .add(3, 'hours')
                        .format('DD/MM/YY HH:mm')}
                    </TableCell>
                    <TableCell>{order.evento.titulo}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>
                      {moneyFormatter.format(order.valor_pedido)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  )
}
