import { Box, Container, Grid, Link, Stack } from '@mui/material'
import { useEffect } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import { CheckSquare } from '@phosphor-icons/react'
import AppImage from 'assets/img/app-image.png'
import AppStoreBadge from 'assets/img/app-store-badge.png'
import GooglePlayBadge from 'assets/img/google-play-badge.png'
import { Typography } from 'components/Typography'

export function DownloadApp() {
  const APKUrl =
    'https://play.google.com/store/apps/details?id=br.com.zedoingresso.cliente'

  const iOSUrl =
    'https://apps.apple.com/us/app/z%C3%A9-do-ingresso/id1641520617'

  useEffect(() => {
    if (isAndroid) {
      window.location.href = APKUrl
    } else if (isIOS) {
      window.location.href = iOSUrl
    }
  }, [])

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(
        to bottom, rgba(0, 25, 114, 1), rgba(13, 23, 111, 0.9), rgba(219, 15, 101,0.9)
        ), url("/img/background-home.jpg")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        pt: 6,
        minHeight: '70vh',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" fontWeight="bold" color="white">
              BAIXE NOSSO APP NA LOJA DE APLICATIVO DO SEU CELULAR!
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={8} spacing={4}>
            <Grid item>
              <Stack direction="row" alignItems="center" gap={2}>
                <CheckSquare size={32} color="#ffc217" />
                <Typography variant="h4" color="white">
                  Acesse seu ingresso com agilidade
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center" gap={2}>
                <CheckSquare size={32} color="#ffc217" />
                <Typography variant="h4" color="white">
                  Entre mais rápido nos eventos
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center" gap={2}>
                <CheckSquare size={32} color="#ffc217" />
                <Typography variant="h4" color="white">
                  Seu ingresso seguro com seus dados
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center" gap={4}>
                <Link href={APKUrl}>
                  <img
                    src={GooglePlayBadge}
                    alt="botão do Google Play"
                    height={42}
                  />
                </Link>
                <Link href={iOSUrl}>
                  <img
                    src={AppStoreBadge}
                    alt="botão da App Store"
                    height={42}
                  />
                </Link>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={AppImage} alt="" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
