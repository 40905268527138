import CancelIcon from '@mui/icons-material/Cancel'
import { LoadingButton } from '@mui/lab'
import { Alert, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AxiosError } from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'

type CancelOrderButtonProps = {
  orderId: string
}

const CancelOrderButton = ({ orderId }: CancelOrderButtonProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancelOrder = React.useCallback(() => {
    handleClose()
    functionWithLoading(
      apiV1.orderService
        .cancelOrder(orderId)
        .then(() => navigate('/usuario/pedidos'))
        .catch((err: AxiosError) =>
          setMessage(
            err.response?.data.message ??
              'Ocorreu um erro ao cancelar o pedido, tente novamente ou entre em contato com o suporte!',
          ),
        ),
      setLoading,
    )
  }, [navigate, orderId])

  return (
    <React.Fragment>
      {!!message && (
        <Alert severity="error" sx={{ my: 1 }} onClose={() => setMessage('')}>
          {message}
        </Alert>
      )}
      <LoadingButton
        loadingPosition="start"
        startIcon={<CancelIcon />}
        loading={loading}
        disabled={loading}
        onClick={handleClickOpen}
        variant="outlined"
        color="error"
      >
        Cancelar pedido
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Cancelar o pedido?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O pedido será estornado de acordo com os termos de uso da plataforma
            e o meio de pagamento utilizado na compra.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOrder} variant="contained">
            Sim
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="error"
            autoFocus
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default CancelOrderButton
