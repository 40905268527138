import { zodResolver } from '@hookform/resolvers/zod'
import CloseIcon from '@mui/icons-material/Close'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Loading } from 'components'
import { addHours } from 'date-fns'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { apiV1 } from 'services'
import { IIngresso } from 'types/ingresso'
import { ISetor } from 'types/setor'
import { functionWithLoading } from 'utils'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'
import BatchDeleteButton from '../BatchDeleteButton'
import SectorAddButton from '../SectorAddButton'
import { constants } from 'config'

const schema = z.object({
  titulo: z.string({ required_error: 'Por favor preencha o título' }),
  descricao: z.string(),
  data_entrar: z.coerce.date(),
  data_sair: z.coerce.date(),
  cor_legenda: z.string({ required_error: 'Por favor selecione a cor' }),
  valor: z.number().min(0),
  taxa_fixa: z.coerce.number().min(0),
  taxa_percentual: z.coerce.number().min(0),
  taxa_absorvida: z.coerce.number().min(0),
  valida_quantidade: z.coerce.boolean(),
  quantidade_disponivel: z
    .number({
      required_error: 'Por favor preencha a quantidade disponivel para venda',
    })
    .min(0),
  valida_pack: z.boolean(),
  quantidade_pack: z.coerce.number().min(1),
  app_cliente: z.coerce.boolean(),
  app_organizador: z.coerce.boolean(),
  site: z.coerce.boolean(),
  visivel: z.coerce.boolean(),
  esgotado: z.coerce.boolean(),
  imprimir: z.coerce.boolean(),
  pode_transferir: z.coerce.boolean(),
  cod_setor: z.number({ required_error: 'É necessário selecionar o setor' }),
  imagem: z.coerce.string().nullish(),
  max_por_usuario: z.coerce.number().positive().min(1, 'Mínimo de 1 ingresso'),
})

type TicketBatchFormData = z.infer<typeof schema>

type Props = {
  eventId: number
  ticketId: number
  callback: () => void
}

export function TicketFormAdmin({ eventId, ticketId = 0, callback }: Props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [sectors, setSectors] = useState<ISetor[]>([])
  const [localBatch, setLocalBatch] = useState<IIngresso>({} as IIngresso)

  const methods = useForm<TicketBatchFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      titulo: '',
      descricao: '',
      data_entrar: new Date(),
      data_sair: new Date(),
      cor_legenda: '#FFFFFF',
      valor: 0,
      taxa_fixa: 0,
      taxa_percentual: 10,
      valida_quantidade: true,
      quantidade_disponivel: 1000,
      valida_pack: false,
      quantidade_pack: 1,
      app_cliente: true,
      app_organizador: true,
      site: true,
      taxa_absorvida: 0,
      visivel: true,
      esgotado: false,
      cod_setor: 0,
      imprimir: false,
      max_por_usuario: 10,
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    watch,
    setValue,
  } = methods

  const [imgSrc, setImgSrc] = useState('')
  const imgRef = useRef<HTMLImageElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [selectedFile, setSelectedFile] = useState<File>()

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
      setSelectedFile(e.target.files[0])
    }
  }

  const load = useCallback(() => {
    if (ticketId !== 0) {
      functionWithLoading(
        apiV1.admin.ticketService
          .get(eventId, ticketId)
          .then(({ data }) => {
            reset(data as TicketBatchFormData)
            setLocalBatch(data)
            if (data.imagem) {
              setImgSrc(constants.URL_FTP + data.imagem)
            }
          })
          .catch((err) => {
            setError(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    } else {
      setLoading(false)
    }
  }, [eventId, reset, ticketId])

  const loadSectors = useCallback(() => {
    apiV1.admin.sectorService.getAll(eventId).then(({ data }) => {
      setSectors(data)
      if (data.length > 0) {
        setValue('cod_setor', ticketId === 0 ? data[0].id : watch('cod_setor'))
      }
    })
  }, [eventId, setValue, ticketId, watch])

  useEffect(() => {
    load()
    if (ticketId === 0) {
      apiV1.eventService
        .getForSite(eventId, '')
        .then(({ data }) =>
          setValue(
            'data_sair',
            addHours(new Date(data.data_encerrar_vendas), 3),
          ),
        )
    }
    loadSectors()
  }, [eventId, load, loadSectors, setValue, ticketId])

  const onSubmit = async (data: TicketBatchFormData) => {
    if (isSubmitting) {
      return
    }

    if (selectedFile) {
      const formData = new FormData()
      formData.append('imagem', selectedFile)

      const response = await apiV1.admin.uploadService.batchImage(formData)
      data.imagem = response.data.path
    } else {
      data.imagem = null
    }

    if (ticketId === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      functionWithLoading(
        apiV1.admin.ticketService
          .create(eventId, data as IIngresso)
          .then(() => {
            setSuccess('Ingresso cadastrado!')
            callback()
          })
          .catch((err) => {
            setError(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    } else {
      functionWithLoading(
        apiV1.admin.ticketService
          .update(eventId, {
            ...data,
            id: ticketId,
          } as IIngresso)
          .then(({ data }) => {
            setSuccess('Ingresso atualizado!')
            reset(data)
          })
          .catch((err) => {
            setError(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    }
  }

  if (loading) return <Loading />

  let taxValue =
    (Number(watch('valor')) * Number(watch('taxa_percentual'))) / 100 +
    Number(watch('taxa_fixa'))
  if (taxValue < 3) taxValue = 3

  const onImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {ticketId === 0 ? 'Cadastrar' : 'Atualizar'} ingresso
            </Typography>
            <IconButton
              aria-label="delete"
              size="large"
              color="primary"
              onClick={callback}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            component={Card}
            variant="outlined"
            sx={{ p: 1, overflow: 'visible' }}
          >
            <Stack direction="row" justifyContent="space-between">
              <FormLabel component="legend">Detalhes</FormLabel>

              {!!ticketId && (
                <BatchDeleteButton
                  ticket={localBatch}
                  isAdmin
                  callback={callback}
                >
                  Remover esse lote
                </BatchDeleteButton>
              )}
            </Stack>

            <FormGroup>
              <Grid item xs={12} container spacing={1} pt={2}>
                <Grid container spacing={1} item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Título"
                      error={!!errors.titulo}
                      helperText={errors.titulo?.message}
                      {...register('titulo')}
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Descrição"
                      error={!!errors.descricao}
                      helperText={errors.descricao?.message}
                      {...register('descricao')}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} sm={6}>
                  <Grid item xs={4}>
                    <TextField
                      label="Imagem"
                      fullWidth
                      type={imgSrc ? 'hidden' : 'file'}
                      inputProps={{ accept: 'image/*' }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: imgSrc && (
                          <img
                            ref={imgRef}
                            alt="Selecione a imagem"
                            src={imgSrc}
                            style={{
                              // width: '100%',
                              maxHeight: 60,
                              maxWidth: 60,
                              objectFit: 'cover',
                              cursor: 'pointer',
                              borderRadius: '5px',
                            }}
                            onClick={onImageClick}
                          />
                        ),
                        endAdornment: !!imgSrc && (
                          <IconButton
                            onClick={() => {
                              setSelectedFile(undefined)
                              setImgSrc('')
                            }}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                            }}
                          >
                            <CloseIcon color="error" />
                          </IconButton>
                        ),
                        sx: { height: '100%' },
                      }}
                      size="small"
                      sx={{ height: '100%' }}
                      {...register('imagem')}
                      onChange={onSelectFile}
                    />
                  </Grid>
                  <Grid container spacing={1} item xs={8}>
                    <Grid item xs={12}>
                      <Controller
                        name="cor_legenda"
                        control={control}
                        defaultValue="#FFFFFF"
                        render={({ field }) => (
                          <>
                            <TextField
                              label="Cor ingresso"
                              onClick={() => setDisplayColorPicker((x) => !x)}
                              contentEditable={false}
                              value={field.value}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    sx={{
                                      padding: '5px',
                                      background: '#fff',
                                      borderRadius: '1px',
                                      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                      display: 'inline-block',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: '35px',
                                        height: '14px',
                                        borderRadius: '2px',
                                        background: field.value,
                                      }}
                                    />
                                  </Box>
                                ),
                              }}
                              fullWidth
                              error={!!errors.cor_legenda}
                              helperText={errors.cor_legenda?.message}
                              required
                            />

                            {displayColorPicker && (
                              <Box sx={{ position: 'absolute', zIndex: '2' }}>
                                <Box
                                  sx={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                  }}
                                  onClick={() => setDisplayColorPicker(false)}
                                />
                                <SketchPicker
                                  color={field.value}
                                  {...register('cor_legenda')}
                                  onChange={(e) => field.onChange(e.hex)}
                                />
                              </Box>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="flex-end">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="setor-select">Setor</InputLabel>
                          <Controller
                            control={control}
                            name="cod_setor"
                            render={({ field: { onChange, value } }) => (
                              <Select
                                value={value}
                                onChange={onChange}
                                label="Setor"
                                labelId="setor-select-label"
                                size="small"
                                required
                              >
                                {sectors?.map((sector) => (
                                  <MenuItem
                                    key={'sector_' + sector.id}
                                    value={sector.id}
                                  >
                                    {sector.titulo}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <SectorAddButton
                          eventId={eventId}
                          callback={loadSectors}
                          isAdmin
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            component={Card}
            variant="outlined"
            sx={{ p: 1 }}
          >
            <FormLabel component="legend">Valor e taxa</FormLabel>
            <FormGroup>
              <Grid item xs={12} container spacing={1} pt={2}>
                <Grid item xs={12} sm={3} sx={{ pr: 2 }}>
                  <TextField
                    label="Preço"
                    error={!!errors.valor}
                    helperText={errors.valor?.message}
                    fullWidth
                    {...register('valor', {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    type="number"
                    size="small"
                    prefix="R$"
                    required
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Taxa percentual"
                    error={!!errors.taxa_percentual}
                    helperText={errors.taxa_percentual?.message}
                    fullWidth
                    {...register('taxa_percentual', {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    type="number"
                    size="small"
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Taxa fixa"
                    error={!!errors.taxa_fixa}
                    helperText={errors.taxa_fixa?.message}
                    fullWidth
                    {...register('taxa_fixa', {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    type="number"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Controller
                    name="taxa_absorvida"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Grid container spacing={1}>
                        <Grid item xs={9} sm={4} sx={{ pl: 1, pb: 1 }}>
                          <Typography
                            variant="caption"
                            id="input-slider"
                            gutterBottom
                          >
                            Absorver taxa (%)
                          </Typography>
                          <Slider
                            value={field.value}
                            onChange={field.onChange}
                            aria-labelledby="input-slider"
                          />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                          <TextField
                            value={field.value}
                            size="small"
                            onChange={(e) =>
                              field.onChange(Number(e.target.value))
                            }
                            onBlur={() => {
                              if (field.value < 0) {
                                field.onChange(0)
                              } else if (field.value > 100) {
                                field.onChange(100)
                              }
                            }}
                            inputProps={{
                              step: 10,
                              min: 0,
                              max: 100,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                              prefix: '%',
                            }}
                            InputProps={{ endAdornment: '%' }}
                            type="number"
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            label="Taxa do cliente"
                            disabled
                            size="small"
                            value={moneyFormatter.format(
                              taxValue -
                                taxValue *
                                  (field.value > 0 ? field.value / 100 : 0),
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            label="Taxa do produtor"
                            disabled
                            size="small"
                            value={moneyFormatter.format(
                              taxValue *
                                (field.value > 0 ? field.value / 100 : 0),
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            component={Card}
            variant="outlined"
            sx={{ p: 1 }}
          >
            <FormLabel component="legend">Validações de venda</FormLabel>
            <FormGroup>
              <Grid item xs={12} container spacing={1} pt={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="data_entrar"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DateTimePicker
                        label="Data e hora de inicio das vendas"
                        value={dayjs(field.value).toDate()}
                        inputRef={field.ref}
                        onChange={(date) => {
                          field.onChange(date)
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors.data_entrar,
                            helperText: errors.data_entrar?.message,
                            fullWidth: true,
                            size: 'small',
                          },
                        }}
                        disablePast
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="data_sair"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DateTimePicker
                        label="Data e hora de término das vendas"
                        value={dayjs(field.value).toDate()}
                        inputRef={field.ref}
                        onChange={(date) => {
                          field.onChange(date)
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors.data_sair,
                            helperText: errors.data_sair?.message,
                            fullWidth: true,
                            size: 'small',
                          },
                        }}
                        disablePast
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Quantidade disponível para venda"
                    error={!!errors.quantidade_disponivel}
                    helperText={errors.quantidade_disponivel?.message}
                    {...register('quantidade_disponivel', {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="esgotado"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox {...field} checked={!!field.value} />
                        }
                        label="Exibir como esgotado ao atingir o limite de venda"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Quantidade máxima ingressos por usuário"
                    error={!!errors.max_por_usuario}
                    helperText={errors.max_por_usuario?.message}
                    {...register('max_por_usuario')}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            component={Card}
            variant="outlined"
            sx={{ p: 1 }}
          >
            <FormLabel component="legend">Outras opções</FormLabel>
            <FormGroup sx={{ display: 'flex', gap: 1, pt: 1 }}>
              <TextField
                label="Quantidade de ingressos gerados por lote"
                error={!!errors.quantidade_pack}
                helperText={errors.quantidade_pack?.message}
                {...register('quantidade_pack')}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                size="small"
                fullWidth
                required
              />

              <Controller
                name="pode_transferir"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Permitir transferência do ingresso"
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            component={Card}
            variant="outlined"
            sx={{ p: 1 }}
          >
            <FormLabel component="legend">Disponibilidade</FormLabel>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
              <Controller
                name="app_cliente"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Aplicativo do cliente"
                  />
                )}
              />
              <Controller
                name="app_organizador"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Aplicativo do organizador"
                  />
                )}
              />
              <Controller
                name="site"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Site"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="visivel"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Ativo (ao desmarcar fica disponível somente para produtores)"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="imprimir"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Disponível para impressão"
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          mb={2}
        >
          <Button variant="outlined" color="error" onClick={callback}>
            Fechar
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveOutlinedIcon />}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
