declare global {
  interface Window {
    jivo_api: {
      setContactInfo: (contactInfo: {
        name: string
        email: string
        phone: string
        description: string
      }) => void
    }
  }
}

function setContactInfo({
  name,
  email,
  phone,
  description = '',
}: {
  name: string
  email: string
  phone: string
  description?: string
}) {
  window.jivo_api.setContactInfo({
    name,
    email,
    phone: '+55' + phone,
    description,
  })
}

export default {
  setContactInfo,
}
